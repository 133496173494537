jQuery(function($) {

	// CasaMiaAjax is required to continue
	if (typeof CasaMiaAjax === 'undefined') {
		return false;
	}

	/**
	 * Object to handle multiselect UI.
	 */
	var multiSelect = {

		multiSelects: false,
		multiSelectForm: $('form.multiselect-form'),

		/**
		 * Initialize multiselect UI events
		 */
		init: function() {

			// Get all multiselects
			this.initMultiSelects();

			// Toggle a multiselect
			this.multiSelectForm.on('mousedown touchend focusin focusout', '.multiselect-trigger', this.toggleMultiSelect);

			// Form submission
			this.multiSelectForm.on('submit', this.multiSelectSubmit);
			
			// Close all multiselects
			$(document.body).on('mouseup', this.closeAllMultiSelects);

			// Check user has scrolled to bottom of the list
			$('.multiselect-list').bind('scroll', this.isScrolledToBottom);
			
			// Search filter for multiselect
			$(document.body).on('keyup', 'input[name="multiselect-search"]', this.searchMultiSelect);
			
			// Apply filter choices to a multiselect
			$(document.body).on('click', '.multiselect-apply', this.applyMultiSelect);

			// Clear any filter choices for a multiselect
			$(document.body).on('click', '.multiselect-clear', this.clearMultiSelect);

			// Clear filters from all multiselects
			$(document.body).on('click', '.multiselect-clear-all', this.clearAllMultiSelects);

			// Apply checkbox selection(s)
			$(document.body).on('change', 'input[type^=checkbox]', this.applyCheckedOptions);
		},

		/**
		 * Get all multiselects in the form.
		 */
		getMultiSelects: function() {
			multiSelect.multiSelects = multiSelect.multiSelectForm.find('.multiselect');
			return multiSelect.multiSelects;
		},

		/**
		 * @TODO
		 */
		initMultiSelects: function() {
			var $multiSelects = multiSelect.getMultiSelects();
            [].forEach.call($multiSelects, function(multiselect) {

            });
		},

		/**
		 * Toggle a multiselect.
		 */
		toggleMultiSelect: function(evt) {
	        var state = $(this).data('state') || 0;
			multiSelect.closeMultiSelect(evt);
			if (state == 0) {
	            $(this).data('state', '1').addClass('open').removeClass('closed');
	            $(this).find('.fas').addClass('fa-chevron-up').removeClass('fa-chevron-down');
	            $(this).parent().find('.multiselect-contents, .multiselect-search').show();
	            multiSelect.selectedMultiSelect = $(this).parent();
	            multiSelect.multiSelectCanScroll(multiSelect.selectedMultiSelect);
	        } else {
	            $(this).data('state', '0').addClass('closed').removeClass('open');
	            $(this).find('.fas').addClass('fa-chevron-down').removeClass('fa-chevron-up');
	            $(this).parent().find('.multiselect-contents, .multiselect-search').hide();
	        }
	        evt.preventDefault();
		},

		/**
		 * Close a multiselect.
		 */
		closeMultiSelect: function(evt) {
	        if ($('.multiselect-trigger').hasClass('open')) {
	            $('.multiselect-trigger').data('state', '0').removeClass('open');
	            $('.multiselect-trigger').find('.fas').removeClass('fa-chevron-up').addClass('fa-chevron-down');
	            $('.multiselect-trigger').parent().find('.multiselect-contents, .multiselect-search').hide();
	        }
		},

		/**
		 * Close all multiselects.
		 */
		closeAllMultiSelects: function(evt) {
	        if ($('.multiselect-trigger').hasClass('open')) {
	        	var elements = '.multiselect-trigger, .multiselect-contents, .multiselect-search, .multiselect-apply';
	            if (! $(elements).is(evt.target) && $(elements).has(evt.target).length === 0) {
	                $('.multiselect-trigger').data('state', '0').removeClass('open');
	                $('.multiselect-trigger').find('.fas').removeClass('fa-chevron-up').addClass('fa-chevron-down');
	                $('.multiselect-contents, .multiselect-search').hide();
	            }
	        }
		},

		/**
		 * Toggle a multiselect.
		 */
		isScrolledToBottom: function(evt) {
			var $el = $(this);
		    if ($el[0].scrollHeight - $el.scrollTop() - $el.outerHeight() < 1) {
		    	$el.addClass('at-bottom');
		    } else {
		    	$el.removeClass('at-bottom');
		    }
		},

		/**
		 * Toggle a multiselect.
		 */
		multiSelectCanScroll: function(multiselect) {
			var $el = multiselect.find('.multiselect-list');
		    if ($el[0].scrollHeight - $el.scrollTop() - $el.outerHeight() < 1) {
		    	$el.addClass('at-bottom');
		    } else {
		    	$el.removeClass('at-bottom');
		    }
		},

		/**
		 * Clear a multiselect checked options.
		 *
		 * @param {Object} multiselect The multiselect JQuery object
		 */
		clearCheckedOptions: function(multiselect) {
	        var checkedOptions = multiselect.find('input[type^=checkbox]:checked');
            checkedOptions.each(function() {
                $(this).prop('checked', false);
            });
		},

		/**
		 * Get the currently checked options for a multiselect
		 *
		 * @param {Object} multiselect The multiselect JQuery object
		 */
		getCheckedOptions: function(multiselect) {
			return multiselect.find('input[type^=checkbox]:checked');
		},

		/**
		 * Apply the currently checked options for a multiselect.
		 */
		applyCheckedOptions: function(evt) {
	        evt.preventDefault();
	        var multiselect = $(evt.currentTarget).closest('.multiselect');
	        var multiSelectType = multiSelect.multiSelectType(multiselect);
	        if (multiSelect.multiSelectHasSearch(multiselect)) {
	            multiSelect.multiSelectClearSearch(multiselect);
	        }
	        multiSelect.multiSelectHandler(multiselect, 'applyMultiSelect');
		},

		/**
		 * Clear the current selected.
		 */
		clearCurrentSelection: function(title, selected) {
            if (selected != null) {
                title.removeClass('d-none');
                selected.addClass('d-none');
                selected.find('span').text('');
            }
		},

		/**
		 * Get the current selected.
		 *
		 * @param 
		 */
		getCurrentSelection: function(qty, type) {
            var multiSelectTypeStr = (qty > 1) ? type + 's' : type;
            return qty + ' ' + multiSelectTypeStr + ' selected';
		},

		/**
		 * Set the current selected.
		 */
		setCurrentSelection: function(title, selected, current) {
            if (selected != null) {
                title.addClass('d-none');
                selected.removeClass('d-none');
                selected.find('span').text(current);
            }
		},

		/**
		 * Apply a multiselect.
		 */
		applyMultiSelect: function(evt) {
	        evt.preventDefault();
	        var multiselect = $(evt.currentTarget).closest('.multiselect');
	        var multiSelectType = multiSelect.multiSelectType(multiselect);
	        if (multiSelect.multiSelectHasSearch(multiselect)) {
	            multiSelect.multiSelectClearSearch(multiselect);
	        }
	        multiSelect.closeMultiSelect();
	        multiSelect.multiSelectHandler(multiselect, 'applyMultiSelect');
		},

		/**
		 * Clear a multiselect.
		 */
		clearMultiSelect: function(evt) {
	        evt.preventDefault();
	        var multiselect = $(evt.currentTarget).closest('.multiselect');
	        var multiSelectType = multiSelect.multiSelectType(multiselect);
	        multiSelect.clearCheckedOptions(multiselect);
	        multiSelect.multiSelectClearSearch(multiselect);
	        multiSelect.clearTypeArray(multiSelectType);
	        multiselect.find('.multiselect-list li').removeClass('d-none');
	        multiSelect.multiSelectHandler(multiselect, 'clearMultiSelect');
		},

		/**
		 * Clear all multiselects.
		 */
		clearAllMultiSelects: function(evt) {
	        evt.preventDefault();
	        $('.multiselect-clear').trigger('click');
	        if (CasaMiaAjax.hasQueryVars()) {
		        multiSelect.multiSelects.each(function(){
		        	var multiSelectType = multiSelect.multiSelectType($(this));
		        	multiSelect.clearTypeArray(multiSelectType);
		        	CasaMiaAjax.removeQueryVariable(multiSelectType + '_ids');
		        });
		    }
		},

		/**
		 * Search filter a multiselect.
		 */
		searchMultiSelect: function(evt) {
	        evt.preventDefault();
	        var multiSearch = $(evt.currentTarget);
	        var multiselect = multiSearch.closest('.multiselect');
			var selectItems = multiselect.find('.multiselect-list li');
	        if (multiSearch != null) {
	            var text = evt.target.value,
	                pattern = new RegExp(text, 'i');
	            if (selectItems != null) {
	            	selectItems.each(function(){
	                    var postcode = $(this).data('postcode');
	                    if (pattern.test($(this).text()) || pattern.test(postcode)) {
	                        $(this).removeClass('d-none');
	                    } else {
	                        $(this).addClass('d-none');
	                    }
	            	});
	            }
	        }
		},

		/**
		 * Conditional check if the multiselect has a search input
		 *
		 * @param {Object} multiselect The multiselect JQuery object
		 */
		multiSelectHasSearch: function(multiselect) {
			return multiselect.find('.multiselect-search').length;
		},

		/**
		 * Clear the multiselect search input
		 *
		 * @param {Object} multiselect The multiselect JQuery object
		 */
		multiSelectClearSearch: function(multiselect) {
			multiselect.find('.multiselect-search input[name="multiselect-search"]').val('');
		},

		/**
		 * Handle multiselect form submit and route to correct logic.
		 *
		 * @param {Object} evt The JQuery event
		 */
		multiSelectSubmit: function(evt) {
			
			var submit = 0;
			
			/**
			 * Only action inputs within the
			 * form currently being submitted.
			 */
			$multiSelects = $(this).find('.multiselect');
	        $multiSelects.each(function(){
	        	var multiSelectType = multiSelect.multiSelectType($(this));
	        	var multiSelectTypeArray = $('input[name="' + multiSelectType + '_ids"]');
	        	$('input[name="' + multiSelectType + 's[]"]').prop('disabled', true);
				if (multiSelectTypeArray.val() == ''){
					multiSelectTypeArray.prop('disabled', true);
					submit++;
				}
	        });

			/**
			 * Disable ALL search inputs 
			 * within ALL the multiselects.
			 */
	        $('input[name="multiselect-search"]').prop('disabled', true);

			/**
			 * If nothing to submit then reload 
			 * rather than submitting the form.
			 */
			if (submit >= $multiSelects.length) {
			    evt.preventDefault();
			    window.location = multiSelect.multiSelectForm.attr('action');
			}
		},

		/**
		 * Retrieve and set the multiselect entity type.
		 *
		 * @param {Object} multiselect The multiselect JQuery object
		 */
		multiSelectType: function(multiselect) {
			return multiselect.attr('data-multiselect-type');
		},

		/**
		 * Define the multiselect entity type array.
		 *
		 * @param {String}
		 */
		defineTypeArray: function(type) {
			var typeArray = type + '_array';
			typeArray = [];
			return typeArray;
		},

		/**
		 * Build the multiselect entity type array.
		 *
		 * @param  
		 * @param string 
		 */
		buildTypeArray: function(options, type) {
			var typeArray = multiSelect.defineTypeArray(type);
            options.each(function() {
                typeArray.push($(this).val());
            });
            return typeArray;
		},

		/**
		 * Parse the multiselects entity type array.
		 *
		 * @param array
		 * @param string 
		 */
		parseTypeArray: function(typeArray, type) {
			//var hiddenInput = $('#' + type + '_ids');
			var hiddenInput = $('input[name="' + type + '_ids"]');
            if (hiddenInput != null) {
                if (JSON.stringify(typeArray, null, ' ') !== '[]') {
                    //var ids = JSON.stringify(typeArray);
                    var ids = typeArray.join(',');
                    hiddenInput.val(ids);
                }
            }
		},

		/**
		 * Clear the multiselects entity type array.
		 *
		 * @param string 
		 */
		clearTypeArray: function(type) {
			var hiddenInput = $('input[name="' + type + '_ids"]');
            if (hiddenInput != null) {
                hiddenInput.val('');
            }
		},

		/**
		 * Main handler for the multiselect dropdowns.
		 *
		 * @param {Object} multiselect The multiselect JQuery object
		 * @param 
		 */
		multiSelectHandler: function(multiselect, called) {

			var multiSelectType     = multiSelect.multiSelectType(multiselect);
			var multiSelectTitle    = multiselect.find('.multiselect-title');
			var multiSelectSelected = multiselect.find('.multiselect-selected');

            switch (called) {
                case 'clearMultiSelect':
                	multiSelect.clearCurrentSelection(multiSelectTitle, multiSelectSelected);
					break;
                case 'applyMultiSelect':
		            var checkedOptions = multiSelect.getCheckedOptions(multiselect);
		            var qtyCheckedOptions = checkedOptions.length;
		            if (qtyCheckedOptions >= 1) {
			            var currentSelected = multiSelect.getCurrentSelection(qtyCheckedOptions, multiSelectType);
			            multiSelect.setCurrentSelection(multiSelectTitle, multiSelectSelected, currentSelected);
			            var typeArray = multiSelect.buildTypeArray(checkedOptions, multiSelectType);
			            multiSelect.parseTypeArray(typeArray, multiSelectType);
		            } else {
			            multiSelect.clearCurrentSelection(multiSelectTitle, multiSelectSelected);
			            multiSelect.clearTypeArray(multiSelectType);
		            }
                    break;
                default:
            }
		}
	};

	multiSelect.init();
});
